/**
 * @generated SignedSource<<afb98642a2611bf23420faab5a043088>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
export type CreateMembershipPlanInput = {
  organizationId: string;
  name: string;
  amountCents: number;
  frequency: PricingFrequency;
  kind: PricingKind;
  productIds?: ReadonlyArray<string> | null;
};
export type CreateMembershipPlanModalMutation$variables = {
  input: CreateMembershipPlanInput;
  connections: ReadonlyArray<string>;
};
export type CreateMembershipPlanModalMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AdminMembershipsReportTableRowFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type CreateMembershipPlanModalMutation = {
  variables: CreateMembershipPlanModalMutation$variables;
  response: CreateMembershipPlanModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMembershipPlanModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateMembershipPlanResponse",
        "kind": "LinkedField",
        "name": "createMembershipPlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdminMembershipsReportTableRowFragment"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateMembershipPlanModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateMembershipPlanResponse",
        "kind": "LinkedField",
        "name": "createMembershipPlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFreeMembership",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "roles",
                    "value": [
                      "member"
                    ]
                  }
                ],
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "productMemberships(roles:[\"member\"])"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipBenefitNodeConnection",
                "kind": "LinkedField",
                "name": "membershipBenefits",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductRevenueReportResponse",
                "kind": "LinkedField",
                "name": "revenueReport",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  }
                ],
                "concreteType": "MisconfiguredStripeObjectNodeConnection",
                "kind": "LinkedField",
                "name": "misconfiguredStripeObjects",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "misconfiguredStripeObjects(first:10)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Pricing",
                "kind": "LinkedField",
                "name": "pricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequency",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountCents",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "typeTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaUrl",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cover",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "node",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ProductNodeEdge"
              }
            ]
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c70eac97f8390a3c7e1a08bcf431b6f6",
    "id": null,
    "metadata": {},
    "name": "CreateMembershipPlanModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMembershipPlanModalMutation(\n  $input: CreateMembershipPlanInput!\n) {\n  response: createMembershipPlan(input: $input) {\n    node {\n      id\n      ...AdminMembershipsReportTableRowFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment AdminMembershipsReportTableRowFragment on Product {\n  id\n  name\n  status\n  isFreeMembership\n  slug\n  productMemberships(roles: [member]) {\n    totalCount\n  }\n  membershipBenefits {\n    totalCount\n  }\n  revenueReport {\n    allTime\n  }\n  misconfiguredStripeObjects(first: 10) {\n    totalCount\n  }\n  ...DeleteMembershipPlanModalContentFragment\n  ...ProductWithDetailsFragment\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment DeleteMembershipPlanModalContentFragment on Product {\n  id\n}\n\nfragment ProductTypeTagFragment on Product {\n  typeTag {\n    label\n    color\n    id\n  }\n}\n\nfragment ProductWithDetailsFragment on Product {\n  id\n  name\n  startsAt\n  endsAt\n  type\n  slug\n  pricing {\n    ...pricingUtils_usePricingDisplayValue\n    id\n  }\n  ...ProductTypeTagFragment\n  badge {\n    ...BadgeFragment\n    id\n  }\n  cover\n}\n\nfragment pricingUtils_usePricingDisplayValue on Pricing {\n  frequency\n  kind\n  amountCents\n}\n"
  }
};
})();

(node as any).hash = "956a0311055cc2f8c9cff4f932846577";

export default node;
